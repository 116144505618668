section#video{

	#videos{
		width: 60%;
		margin: auto;
		justify-self:center;
		overflow: hidden;

		
	
		.swiper-slide{
			width: 100%;

			iframe{
				width: 100%;
				height: 415px; 
			}
		}
		
	}

	.swiper-wrapper{
		display: flex;
		align-items: center;
	}
	.swiper-button-prev:hover{
		cursor: pointer;
	}
	.swiper-button-prev{
		height: 30px;
		width: 30px;
		text-align: left;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
	}
	.swiper-button-next:hover{
		cursor: pointer;
	}
	.swiper-button-next{
		height: 30px;
		width: 30px;
		text-align: right;
		background-repeat: no-repeat;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
	}

	.swiper-pagination{
		bottom:-25px;
	}


	@media (max-width: $tablet-width) {

        #videos{
			width: 90%;
		}
		.swiper-button-prev{
			margin-left: -10px;
		}
		.swiper-button-next{
			margin-right: -20px;
		}
    }

}