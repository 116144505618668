section#about{

	.container_row{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	#about_container{
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 20px;
		max-width: 40%;
	}
	.swiper-container{
		position: relative;
	}

	#about_img{
		width: 50%;
		overflow: hidden;
		img{
			width: 100%;
			height: 80vh;
			object-fit: cover;
			object-position: top;
			min-height: 800px;
		}
		.swiper-slide{
			display: flex;
			align-items: center;

			.name{
				position: absolute;
				color: white;
				bottom: 0;
				padding-bottom: 20px;
				padding-left: 20px;
			}
		}
		.swiper-button-prev:hover{
			cursor: pointer;
		}
		.swiper-button-prev{
			position: absolute;
			top:50%;
			left: 5px;
			z-index: 1;
			height: 25px;
			width: 25px;
		}
		.swiper-button-next:hover{
			cursor: pointer;
		}
		.swiper-button-next{
			position: absolute;
			top:50%;
			right: 5px;
			z-index: 1;
			height: 25px;
			width: 25px;
		}
	}


	@media (max-width: $mobile-width) {

        .container_row{
            flex-direction: column;
        }

        #about_img{
            width: 100%;
            img{
                max-height: 40vh;
				min-height: 700px;
            }
        }
		#about_container{
			max-width: 90%;
			padding-bottom: 20px;
		}
    }

}