// @import "global";

section#dates{


	.container_row{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	#dates_container{
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-bottom: 20px;

		#current_dates,#old_dates{
			padding-left: 20px;
		}
		#old_dates{
			display: none;
			animation-name: slide_in;
			animation-fill-mode: forwards;
  			animation-duration: 1.2s;
		}
	}

	
	#img_dates{
		width: 40%;
		img{
			width: 100%;
			height: 80vh;
			object-fit: cover;
			object-position: top;
		}
	}


	li{
		display: flex;
		font-size: clamp(18px, 2vw, 22px);
		padding-top:5px;
		padding-bottom: 5px;

		.day{
			float: left;
			padding-right:20px;
		}
	
		.location{
			float: left;
		}
	}

	#past_button{
		padding-top: 20px;
		height: 60px;
		display: flex;
		align-items: center;

		a{
			font-size: clamp(16px, 2vw, 20px);
			white-space: nowrap;
			float: left;
		}
	}

	#dates_arrow{
		height: 25px;
		padding: 25px;
		transform: rotate(90deg);
		transition: all 1s;

	}
	#dates_arrow:hover{
		cursor: pointer;
	}

	.active{
		display: block!important;
		transform: rotate(0deg)!important;
	}

	@keyframes rotate_up{
		to   {transform: rotate(90deg);}
	}

    @keyframes slide_in {
        0%   {opacity: 0;}
        100% {opacity: 1;}
    }


    @media (max-width: $mobile-width) {

        .container_row{
            flex-direction: column;
        }

        #img_dates{
            width: 100%;
            img{
                max-height: 40vh;
            }
        }
    }
}

