@import "modules/reset/reset";
@import "modules/global";

@import "modules/home";
@import "modules/dates";
@import "modules/video";
@import "modules/music";
@import "modules/about";
@import "modules/contact";
@import "modules/imprint";


@import "swiper/css";



html{
	// opacity: 0;
}
body {
	background: #FDFDFD;
	font-family: 'Poppins', sans-serif;
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px; 
}
main{
	margin: auto;
	width: 100vw;
	max-width:100%;
	// overflow: scroll;
	// scroll-behavior: smooth;
	// scroll-snap-type: y mandatory;
	// height: 100vh;
}
a{
	color :black;
	text-decoration: none;
}
hr { color: gray; border-style: inset; border-width: 1px; margin: 0.5em auto; width: 100%; }

section{
	
	// scroll-snap-align: start;
	max-width: $max-width;
	padding-bottom: 200px;
	width: 100%;
	

	h1{
		font-size: clamp(32px, 5vw, 100px);
		font-weight: 700;
		padding-bottom: 10px;
	}
	h2{
		font-size: clamp(24px, 5vw, 50px);
		font-weight: 700;
		padding-top:20px;
		padding-bottom: 50px;
	}
	h3{
		font-size: clamp(16px, 2vw, 60px);
		font-weight: 300;
		padding-bottom: 10px;
	}
	h4{
		font-size: clamp(16px, 1.5vw, 40px);
		font-weight: 600;
	}
	p{
		font-size: clamp(18px, 2vw, 22px);
		line-height: normal;
		font-weight: 300;
	}
	

	@media (max-width: $mobile-width) {

		
		h2{
			padding-bottom: 20px;
		}
	}
	
}

section:last-child{
	padding-bottom: 50px;
}

header{
	flex: 1;
	
	nav{
		ul{
			display: flex;
			justify-content:flex-end;
			align-items: center;
			gap: 12px;
			height: 50px;
		}
		li{
			font-size: clamp(16px, 2vw, 20px);
		}
	}
}


a{
	display: inline-block;
	text-decoration: none;
	position: relative;
	-webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
a:hover{
	cursor: pointer;
}

a:after
{
	content: "";
	position: absolute;
	height: 2px;
	background-color: black;
	width: 0;
	left: 50%;
	bottom: 0;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
	transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
}

a:hover:after {width: 100%;}


@media (min-width: $max-width) {

	main{
		max-width:$max-width;
	}
}


@media (max-width: $mobile-width) {

	body {
		padding-left: 5px;
		padding-right: 5px; 
	}

	section{
		padding-bottom: 60px;
	}
}