section#home{
	flex: 2;
	width: 100%;
	position: relative;


	.image-wrapper{
		position: relative;
	}

	#headline{
		position: absolute;
		bottom: 24%;
		z-index: 1;
		padding-left: 1vw;
		color: white;
	}

	img{
		width: 100%;
		position: relative;
	}
}

@media (max-width: $mobile-width) {

	section#home{
		img{
			width: 100%;
			height: 50dvh;
			object-fit: cover;
			object-position: 39%;
			position: relative;
		}
	}
}