@import "global";

section#contact{

	h2{
		text-align: center;
	}

	.contact-text{
		text-align: center;
		padding-bottom: 20px;

		p:first-of-type {
			padding-bottom: 10px;
		}
		p{
			padding-bottom: 5px;
			line-height: unset;
		}
	}

	img{
		position: relative;
		width: 100%;
		object-fit: cover;
	}


}

