section#imprint{

	text-align: left;
	
	h2{
		font-size: clamp(14px, 2vw, 30px)!important;
		font-weight: 700;
		padding-top:20px;
		padding-bottom: 10px;
	}
	h3{
		font-size: clamp(12px, 2vw, 20px)!important;
		font-weight: 500;
		padding-top:20px;
		padding-bottom: 10px;
	}


}