section#music{

	
	.description{
		p{
			padding-bottom: 20px;
		}
	}

	.music_img_container{
		display: flex;
		flex-wrap: nowrap;
		gap:30px;

		img{
			width: 50%;
		}
	}
	
	
	.music_links_container{
		width: 100%;
		padding-top:40px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap:30px;
	}

	@media (max-width: $tablet-width) {

		.music_img_container{
		
			flex-wrap: wrap;
			img{
				width: 100%;
			}
		}
    }

}